import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import calculateColumn from '/src/functions/columns/calculateColumn'
import parse from 'html-react-parser'
import { parseStyledCheckList } from '/src/functions/parse/styledCheckList'
import { parseStyledOrderedList } from '/src/functions/parse/styledOrderedList'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const ImageVideoSwap = loadable(() => import('/src/components/Base/ImageVideoSwap'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const SectionHeader = loadable(() => import('/src/components/Structure/Section/SectionHeader'))

const ContentWithMediaCtaList = ({
    backgroundColour,
    blockSettings,
    backgroundColourPicker,
    textColourPicker,
    highlightColourPicker,
    titleArea,
    columnSizes,
    mediaLocation,
    mediaType,
    image,
    video,
    addStyledNumberBullets,
    numberBulletsStyle,
    bulletBackgroundColour,
    bulletTextColour,
    contentType,
    content,
    customContent,
    addSmallprint,
    smallprint,
    addCtas,
    ctasRepeater,
    node,
    className,
    utm
}) => {
    const paddingSize = blockSettings?.paddingSize

    const classes = [
        'c-content-with-media-cta-list',
        `c-content-with-media-cta-list--${mediaLocation}`,
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    // Figure out how to size the columns (when images are selected only)
    const mediaColumnWidth = calculateColumn('media', columnSizes, mediaLocation)
    const contentColumnWidth = calculateColumn('content', columnSizes, mediaLocation)

    return (
        <Section
            variant={backgroundColour}
            size={paddingSize}
            titleArea={titleArea}
            className={concatenatedClasses}
            style={backgroundColour === 'other' ? { backgroundColor: `${backgroundColourPicker}` } : {}}
        >
            <div className="row">
                <div
                    className={`${contentColumnWidth} c-content-with-media-cta-list__content-column`}
                    style={backgroundColour === 'other' ? { color: `${textColourPicker}` } : {}}
                >
                    {titleArea.addTitleArea && titleArea.titlePosition === 'inline' && (
                        <SectionHeader variant={'inline'} titleArea={titleArea} />
                    )}

                    {contentType === 'custom' ? (
                        <div className="c-content-with-media-cta-list__custom-content">{customContent}</div>
                    ) : (
                        content &&
                        (addStyledNumberBullets
                            ? numberBulletsStyle === 'custom'
                                ? parse(
                                      content,
                                      parseStyledOrderedList('custom', bulletBackgroundColour, bulletTextColour)
                                  )
                                : backgroundColour === 'dark' || backgroundColour === 'gradient'
                                ? parse(content, parseStyledOrderedList('dark'))
                                : parse(content, parseStyledOrderedList('light'))
                            : parse(content, parseStyledCheckList('light')))
                    )}

                    {addCtas && ctasRepeater && (
                        <CtaContainer variant={'row'}>
                            {ctasRepeater.map((node, index) => {
                                return (
                                    <ButtonLink
                                        key={index}
                                        data={node.callToAction}
                                        variant={node.buttonStyle}
                                        size={'lg'}
                                        icon={node.buttonIcon}
                                        utm={utm}
                                        className={'c-content-with-media-cta-list__cta '}
                                    />
                                )
                            })}
                        </CtaContainer>
                    )}

                </div>
                <div className={`${mediaColumnWidth} c-content-with-media-cta-list__media-column`}>
                    <ImageVideoSwap
                        video={video}
                        image={image}
                        mediaType={mediaType}
                        className={'c-content-with-media-cta-list__media d-none d-sm-none d-md-none d-lg-block'}
                    />
                </div>
            </div>

            {node && <div className="c-content-with-media-cta-list__node">{node}</div>}

                    {/* cta 2 */}

            {addSmallprint && smallprint && (
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <ContentDisclaimer content={smallprint} />
                    </div>
                </div>
            )}
        </Section>
    )
}

ContentWithMediaCtaList.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    backgroundColourPicker: PropTypes.string,
    textColourPicker: PropTypes.string,
    highlightColourPicker: PropTypes.string,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.oneOf(['inline', 'centered']),
        title: PropTypes.string,
        description: PropTypes.string
    }),
    columnSizes: PropTypes.oneOf([
        'media-6-content-6',
        'media-5-content-7',
        'media-4-content-8',
        'media-3-content-9',
        'offset-1-media-5-content-5',
        'offset-1-media-4-content-6',
        'offset-1-media-3-content-7',
        'offset-1-media-2-content-8'
    ]).isRequired,
    mediaLocation: PropTypes.oneOf(['left', 'right']).isRequired,
    mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
    image: PropTypes.object,
    video: PropTypes.string,
    addStyledNumberBullets: PropTypes.bool,
    numberBulletsStyle: PropTypes.oneOf(['default', 'custom']),
    bulletBackgroundColour: PropTypes.string,
    bulletTextColour: PropTypes.string,
    contentType: PropTypes.oneOf(['standard', 'custom']).isRequired,
    content: PropTypes.string,
    customContent: PropTypes.node,
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string,
    addCtas: PropTypes.bool,
    ctasRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            callToAction: PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
                target: PropTypes.string
            }),
            buttonStyle: PropTypes.oneOf(['primary', 'inverse', 'other'])
        })
    ),
    /**
     * Optional additional node to display
     */
    node: PropTypes.node,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ContentWithMediaCtaList.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    columnSizes: 'media-5-content-7',
    mediaLocation: 'left',
    mediaType: 'image',
    addStyledNumberBullets: false,
    numberBulletsStyle: 'default',
    bulletBackgroundColour: '#664674',
    bulletTextColour: '#ffffff',
    contentType: 'standard',
    content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis vitae lacus a bibendum. Fusce nisi turpis, feugiat sit amet dui sed, sollicitudin auctor diam. Integer imperdiet finibus velit quis gravida. Morbi convallis ex sed leo iaculis finibus.</p>
    <ul>
    <li>List item</li>
    <li>List item</li>
    <li>List item</li>
    <li>List item</li>
    </ul>`,
    addCtas: false,
    addSmallprint: false,
    smallprint: '<p>I am some smallprint</p>'
}

export default ContentWithMediaCtaList

export const query = graphql`
    fragment ContentWithMediaCtaListForPageBuilder on WpPage_Acfpagebuilder_Layouts_ContentWithMediaCtaList {
        fieldGroupName
        blockSettings {
            paddingSize
        }
        backgroundColour
        backgroundColourPicker
        textColourPicker
        highlightColourPicker
        addTitleArea
        title
        titlePosition
        description
        columnSizes
        mediaLocation
        mediaType
        image {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 520)
                }
            }
            altText
            title
        }
        video
        addCtas
        ctasRepeater {
            callToAction {
                target
                title
                url
            }
            buttonStyle
            buttonIcon
        }
        addStyledNumberBullets
        numberBulletsStyle
        bulletBackgroundColour
        bulletTextColour
        content
        addSmallprint
        smallprint
    }
`

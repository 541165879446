import * as React from 'react'
import PropTypes from 'prop-types'
import { domToReact } from 'html-react-parser'
import loadable from '@loadable/component'
import { replaceLink } from '/src/functions/parse/gatsbyLink'

const NumberList = loadable(() => import('/src/components/Lists/NumberList'))

export const parseStyledOrderedList = (
    variant,
    backgroundColour,
    textColour
) => {
    const listItem = {
        replace: (domNode) => {
            if (domNode.name === 'li') {
                return (
                    <li
                        style={
                            variant === 'custom'
                                ? {
                                      borderColor: backgroundColour,
                                      color: textColour
                                  }
                                : undefined
                        }
                    >
                        <span>{domToReact(domNode.children)}</span>
                    </li>
                )
            }
        }
    }

    const list = {
        replace: (domNode) => {
            if (domNode.name === 'ol') {
                return (
                    <NumberList variant={variant}>
                        {domToReact(domNode.children, listItem)}
                    </NumberList>
                )
            }

            replaceLink(domNode)
        }
    }

    return list
}

parseStyledOrderedList.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['light', 'dark', 'custom']),
    /**
     * Specify a custom background colour for the bullet
     */
    backgroundColour: PropTypes.string,
    /**
     * Specify a custom colour for the bullet number
     */
    color: PropTypes.string
}
